import React, { useEffect, useState } from 'react';
import { IconButton, Popover } from '@mui/material';
import { UserSettingsSignInAvatar, useUserProfile } from '@backstage/plugin-user-settings';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { InfoCard } from '@backstage/core-components';
import { errorApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router';

export function MeControl() {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [visible, setVisible] = useState(true);
    const location = useLocation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setVisible(true);
    }, [location]);

    useEffect(() => {
        let ticking = false;
        const scrollHandler = () => {
            if (!ticking) {
                setTimeout(() => {
                    const mainElement = document.getElementsByTagName('main')[0];
                    if (mainElement?.scrollTop > 40) {
                        setVisible(false);
                    } else {
                        setVisible(true);
                    }
                    ticking = false;
                }, 200);

                ticking = true;
            }
        };

        document.addEventListener('scroll', scrollHandler, { capture: true });

        return () => {
            document.removeEventListener('scroll', scrollHandler, { capture: true });
        };
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'login-popover' : undefined;

    return (
        <div style={{ position: 'absolute', left: '94%', zIndex: 1000, display: visible ? 'block' : 'none' }}>
            <IconButton aria-label="profile" size="large" onClick={handleClick}>
                <UserSettingsSignInAvatar size={40} />
            </IconButton>

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MeControlPopOverContent />
            </Popover>
        </div>
    );
}

export const MeControlPopOverContent = () => {
    const { profile, displayName } = useUserProfile();
    const identityApi = useApi(identityApiRef);
    const errorApi = useApi(errorApiRef);

    return (
        <div style={{ width: '360px' }}>
            <InfoCard>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <UserSettingsSignInAvatar size={60} />
                    </Grid>
                    <Grid item xs={9} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography variant="subtitle1" gutterBottom>
                                    {displayName}
                                </Typography>
                                {profile.email && (
                                    <Typography variant="body2" color="textSecondary">
                                        {profile.email}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-end">
                            <Button
                                startIcon={<LogoutIcon />}
                                onClick={() => identityApi.signOut().catch(error => errorApi.post(error))}
                            >
                                Log out
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </InfoCard>
        </div>
    );
};
